//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState, mapGetters, mapActions } from 'vuex';
// import Filters from './TableFilters.vue';
import constant from '~/const';
import editFieldsModal from './editFieldsModal.vue';

import tableConfig from './tableConfig';
import actionsConfig from './actionsConfig';

export default {
  components: { editFieldsModal },

  data() {
    return {
      tableConfig,
      actionsConfig,
      selection: [],
      loader: false,
      modalEditFields: {
        show: false,
        loading: false,
      },
      statuses: [
        { id: 'PAYMENT_METHOD_STATUS_ENABLED', caption: 'Enabled' },
        { id: 'PAYMENT_METHOD_STATUS_DISABLED', caption: 'Disabled' },
      ],
      constant,
      editingModal: {
        show: false,
        data: {},
        isCreating: false,
      },
    };
  },

  // SINGLE TABLE COMPONENT
  // async mounted() {
  //   const { page } = this.$route.query;
  //   const numberPage = Number(page);
  //   if (numberPage) {
  //     this.setPageAction(numberPage);
  //   }

  //   this.setGeneralLoader(true);
  //   await this.loadData();
  //   this.setGeneralLoader(false);
  // },

  computed: {
    ...mapState('common', ['generalLoader']),
    ...mapGetters('common', ['itemsPerPage']),

    ...mapState('tradersPaymentMethods', ['page', 'pages', 'data', 'sort']),
    ...mapState('templates', { templates: 'data' }),

    isApplyPossible() {
      return !this.editingModal.data.name
        && !this.editingModal.data.status
        && !this.editingModal.data.templateId;
    },
  },

  methods: {
    ...mapActions('common', [
      'confirmAction',
      'setGeneralLoader',
      'setGeneralProgress',
      'setSuccessNotification',
      'setErrorNotification',
    ]),
    ...mapActions('tradersPaymentMethods', {
      loadDataAction: 'loadData',
      setPageAction: 'setPage',
      setSortAction: 'setSort',
      editAction: 'edit',
      createAction: 'create',
    }),
    ...mapActions('templates', {
      loadTemplates: 'loadData',
    }),

    getStatusColor(statusId) {
      switch (statusId) {
        case 0:
          return 'success--text';
        case 1:
          return 'primary--text';
        case 2:
          return 'error--text';
        default:
          return '';
      }
    },

    async loadData(data = {}) {
      this.setGeneralProgress(true);
      this.loader = true;
      await this.loadDataAction(data);
      this.setGeneralProgress(false);
      this.loader = false;
    },

    statusClass(status) {
      let res = '1';
      switch (status) {
        case constant.traders.PAYMENT_METHOD_STATUS.ENABLED: res = 'success--text';
          break;
        case constant.traders.PAYMENT_METHOD_STATUS.DELETED:
          res = 'error--text';
          break;
        case constant.traders.PAYMENT_METHOD_STATUS.DISABLED: res = 'warning--text';
          break;
        default:
          res = 'success--text';
          break;
      }
      return res;
    },

    applyFilters() {
      const { page } = this;
      if (page > 1) {
        this.setPageAction(1);
        this.$router.replace({ query: {} });
      }

      this.loadData();
    },

    applySort(data) {
      this.setSortAction(data);
      this.loadData();
    },

    setPage(value) {
      this.setPageAction(value);

      // SINGLE TABLE COMPONENT
      // const query = { page: value };
      // this.$router.replace({ query });

      const extendedRequestData = {
        getTotal: false,
      };

      this.loadData(extendedRequestData);
    },

    actionHandler({ action, payload }) {
      this[action](payload);
    },

    async openModalEdit(row) {
      this.setGeneralLoader(true);
      const {
        notMappedFields,
        template,
        fields,
        ...rest
      } = row;
      this.editingModal.data = rest;
      this.editingModal.data.title = rest.title;
      this.editingModal.data.status = row.status;
      this.editingModal.data.templateId = row.template.id;
      this.editingModal.isCreating = false;
      this.editingModal.show = true;
      await this.loadTemplates();
      this.setGeneralLoader(false);
    },

    openModalCreate() {
      this.editingModal.data.name = '';
      this.editingModal.data.status = constant.traders.PAYMENT_METHOD_STATUS.ENABLED;
      this.editingModal.isCreating = true;
      this.editingModal.show = true;
    },

    getFieldsByTemplateId(templateId) {
      return this.templates.find((element) => element.id === templateId)?.fields || [];
    },

    async apply() {
      this.setGeneralProgress(true);
      this.loader = true;
      // Uncomment this if you need to add fields to request
      //
      // this.editingModal.data.fields = this.getFieldsByTemplateId(this.editingModal.data.templateId);
      try {
        if (this.editingModal.isCreating) {
          await this.createAction(this.editingModal.data);
          this.setSuccessNotification('Payment method created');
        } else {
          await this.editAction(this.editingModal.data);
          this.setSuccessNotification('Payment method created');
        }
        await this.loadDataAction();
        this.editingModal.show = false;
      } catch (error) {
        this.setErrorNotification(error.message);
      }
      this.setGeneralProgress(false);
      this.loader = false;
    },

    async deleteMethod(data) {
      this.setGeneralProgress(true);
      this.loader = true;
      this.confirmAction({
        title: 'Are you sure you want to delete this payment method?',
        callback: async () => {
          await this.editAction({ id: data.id, name: data.name, status: 'PAYMENT_METHOD_STATUS_DELETED' });
          await this.loadDataAction();
        },
      });
      this.setGeneralProgress(false);
      this.loader = false;
    },
  },
};
