//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapActions, mapState } from 'vuex';
import DOMPurify from 'dompurify';
import constant from '~/const';

export default {
  props: {
    value: { type: Boolean, default: false },
  },

  computed: {
    ...mapState('templates', ['data']),
    ...mapState('tradersPaymentMethods', { paymentMethodsData: 'data' }),

    model: {
      get() {
        const { value } = this;
        return value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },

    hasDuplicateUniqueNames() {
      const uniqueNamesSet = new Set();
      return this.currentTemplate.fields.some((field) => {
        if (uniqueNamesSet.has(field.uniqueName)) {
          this.errorMessage.text = this.$t('Field name must be unique');
          this.errorMessage.show = true;
          return true;
        }
        uniqueNamesSet.add(field.uniqueName);
        this.errorMessage.text = '';
        this.errorMessage.show = false;
        return false;
      });
    },

    defaultTemplate() {
      return { name: '', fields: [{ ...this.defaultField }] };
    },

    getRelatedPaymentMethods() {
      return this.paymentMethodsData.filter((e) => e.template.name === this.currentTemplate.name);
    },

    getDeleteTemplatText() {
      switch (this.getRelatedPaymentMethods.length) {
        case 0:
          return this.$t('There is no payment methods with this template. You can safely delete this template');
        default:
          return this.$t('This template is linked to', {
            amount: this.getRelatedPaymentMethods.length,
            names: this.getRelatedPaymentMethodsNames(this.getRelatedPaymentMethods),
          });
      }
    },
  },

  data() {
    return {
      constant,
      isEditing: false,
      errorMessage: {
        text: '',
        show: false,
      },
      defaultField: {
        uniqueName: '',
        type: constant.templates.TEMPLATE_TYPES.TEXT,
        IsRequired: true,
        isUniqueRequired: false,
        regex: '',
      },
      currentTemplate: {
        name: undefined,
        fields: [],
      },
      modalManageFieldsTemplates: [
        {
          name: 'Credit card',
          fields: [
            {
              uniqueName: 'cardNumber',
              type: constant.p2p.PAYMENT_FIELD_TYPE.TEXT,
              IsRequired: true,
              // eslint-disable-next-line
              regex: '/^\d{16,18}/',
            },
            {
              uniqueName: 'cardOwner',
              type: constant.p2p.PAYMENT_FIELD_TYPE.TEXT,
              IsRequired: true,
              // eslint-disable-next-line
              regex: '/^[A-Za-z]* [A-Za-z]*/',
            },
            {
              uniqueName: 'validThru',
              type: constant.p2p.PAYMENT_FIELD_TYPE.TEXT,
              IsRequired: true,
              // eslint-disable-next-line
              regex: '/^\d{2}\/\d{2}/',
            },
            {
              uniqueName: 'CVV',
              type: constant.p2p.PAYMENT_FIELD_TYPE.INTEGER,
              IsRequired: true,
              // eslint-disable-next-line
              regex: '/^\d{3}/',
            },
          ],
        },
        {
          name: 'Internet wallets',
          fields: [
            {
              uniqueName: 'number',
              type: constant.p2p.PAYMENT_FIELD_TYPE.TEXT,
              IsRequired: true,
              regex: '',
            },
          ],
        },
        {
          name: 'SBPay',
          fields: [
            {
              uniqueName: 'phoneNumber',
              type: constant.p2p.PAYMENT_FIELD_TYPE.TEXT,
              IsRequired: true,
              regex: '',
            },
          ],
        },
        {
          name: 'Bank transfer',
          fields: [
            {
              uniqueName: 'nameOrOrganization',
              type: constant.p2p.PAYMENT_FIELD_TYPE.TEXT,
              IsRequired: true,
              regex: '',
            },
            {
              uniqueName: 'accountNumberOrIBAN',
              type: constant.p2p.PAYMENT_FIELD_TYPE.TEXT,
              IsRequired: true,
              regex: '',
            },
            {
              uniqueName: 'swiftCodeOrNameOrCorrespondentAccount',
              type: constant.p2p.PAYMENT_FIELD_TYPE.TEXT,
              IsRequired: true,
              regex: '',
            },
          ],
        },
      ],
    };
  },

  async mounted() {
    await this.loadData();
  },

  methods: {
    ...mapActions('common', [
      'confirmAction',
      'setGeneralLoader',
      'setGeneralProgress',
      'setSuccessNotification',
      'setErrorNotification',
    ]),
    ...mapActions('templates', {
      loadTemplates: 'loadData',
      editTemplateAction: 'editTemplate',
      addNewTemplateAction: 'addNewTemplate',
      deleteTemplateAction: 'deleteTemplate',
    }),

    getRelatedPaymentMethodsNames(arr) {
      // Проверяем, если количество элементов в массиве не превышает 20
      if (arr.length <= 20) {
        // Возвращаем имена всех элементов, объединенные запятой
        // и добавляем последнее имя, если в массиве более одного элемента
        return arr.map((item) => item.name).join(', ');
      }
      // Если количество элементов в массиве больше 20
      const maxNamesToShow = Math.ceil(arr.length / 2); // Максимальное количество имен для отображения (50%)
      // Возвращаем имена первых (maxNamesToShow) элементов, объединенные запятой,
      // и добавляем сообщение о оставшихся элементах в конце
      return `${arr.slice(0, maxNamesToShow).map((item) => item.name).join(', ')} ${this.$t('and another')} ${arr.length - maxNamesToShow}`;
    },

    deleteTemplate() {
      this.confirmAction({
        title: this.$t('confirmAction.Are you sure you want to delete this template'),
        text: this.getDeleteTemplatText,
        callback: async () => {
          this.setGeneralLoader(true);
          try {
            await this.deleteTemplateAction(this.currentTemplate.id);
            await this.loadTemplates();
            this.setSuccessNotification('Template successfuly deleted');
          } catch (error) {
            this.setErrorNotification(error.message);
          }
          this.setGeneralLoader(false);
        },
      });
    },

    setCurrent(template, type) {
      this.isEditing = false;
      this.byDefault = false;

      switch (type) {
        case 'new':
          this.isEditing = false;
          this.currentTemplate.name = '';
          this.currentTemplate.fields = template.fields;
          break;
        case 'default':
          this.byDefault = true;
          this.currentTemplate.fields = template.fields;
          this.currentTemplate.name = template.name;
          break;
        default:
          this.isEditing = true;
          this.currentTemplate.name = template.name;
          this.currentTemplate.id = template?.id || undefined;
          this.currentTemplate.fields = template.fields;
          break;
      }
    },

    async loadData() {
      this.setGeneralLoader(true);
      await this.loadTemplates();
      this.setGeneralLoader(false);
    },

    async applyTemplate() {
      const template = { ...this.currentTemplate };
      this.setGeneralProgress(true);
      try {
        if (this.isEditing) {
          await this.editTemplateAction(template);
          this.setSuccessNotification('Template has been successfully edited');
        } else {
          await this.addNewTemplateAction(template);
          this.setSuccessNotification('Template has been successfully created');
        }
        await this.loadData();
        this.setGeneralProgress(false);
      } catch (error) {
        this.setErrorNotification(error);
      }
      this.setGeneralProgress(false);
    },

    sanitize(text) {
      return DOMPurify.sanitize(text, { USE_PROFILES: { html: true } });
    },

    addField() {
      this.currentTemplate.fields.push({
        uniqueName: '',
        type: constant.templates.TEMPLATE_TYPES.TEXT,
        IsRequired: true,
        regex: '',
      });
    },

    deleteField(id) {
      if (this.currentTemplate.fields.length > 1) {
        this.currentTemplate.fields.splice(id, 1);
      }
    },
  },
};
