//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState, mapGetters, mapActions } from 'vuex';
// import Filters from './TableFilters.vue';

import constant from '~/const';
import tableConfig from './tableConfig';
import actionsConfig from './actionsConfig';

export default {
  // components: { Filters },

  data() {
    return {
      tableConfig,
      actionsConfig,
      selection: [],
      loader: false,
      constant,
      editingModal: {
        show: false,
        data: {},
        isCreating: false,
      },
    };
  },

  // SINGLE TABLE COMPONENT
  // async mounted() {
  //   const { page } = this.$route.query;
  //   const numberPage = Number(page);
  //   if (numberPage) {
  //     this.setPageAction(numberPage);
  //   }

  //   this.setGeneralLoader(true);
  //   await this.loadData();
  //   this.setGeneralLoader(false);
  // },

  computed: {
    ...mapState('common', ['generalLoader']),
    ...mapGetters('common', ['itemsPerPage']),

    ...mapState('tradersCurrencies', ['page', 'pages', 'data', 'sort']),
    ...mapState('tradersPaymentMethods', {
      paymentMethods: 'data',
    }),
  },

  methods: {
    ...mapActions('common', [
      'setGeneralLoader',
      'setGeneralProgress',
      'setSuccessNotification',
      'setErrorNotification',
    ]),
    ...mapActions('tradersCurrencies', {
      loadDataAction: 'loadData',
      setPageAction: 'setPage',
      setSortAction: 'setSort',
      editAction: 'edit',
      createAction: 'create',
    }),

    async loadData(data = {}) {
      this.setGeneralProgress(true);
      this.loader = true;
      await this.loadDataAction(data);
      this.setGeneralProgress(false);
      this.loader = false;
    },

    applyFilters() {
      const { page } = this;
      if (page > 1) {
        this.setPageAction(1);
        this.$router.replace({ query: {} });
      }

      this.loadData();
    },

    applySort(data) {
      this.setSortAction(data);
      this.loadData();
    },

    setPage(value) {
      this.setPageAction(value);

      // SINGLE TABLE COMPONENT
      // const query = { page: value };
      // this.$router.replace({ query });

      const extendedRequestData = {
        getTotal: false,
      };

      this.loadData(extendedRequestData);
    },

    actionHandler({ action, payload }) {
      this[action](payload);
    },

    openModalEdit(row) {
      const { paymentMethodIds, ...data } = row;
      this.editingModal.data = { ...data, paymentMethods: paymentMethodIds };
      this.editingModal.isCreating = false;
      this.editingModal.show = true;
    },

    openModalCreate() {
      this.editingModal.data.name = '';
      this.editingModal.data.title = '';
      this.editingModal.data.type = constant.traders.CURRENCY_TYPE.CRYPTO;
      this.editingModal.data.decimal = 8;
      this.editingModal.data.isEnabled = true;
      // this.editingModal.data.isDeleted = true;

      this.editingModal.isCreating = true;
      this.editingModal.show = true;
    },

    async apply() {
      this.setGeneralProgress(true);
      this.loader = true;
      try {
        if (this.editingModal.isCreating) {
          await this.createAction(this.editingModal.data);
        } else {
          await this.editAction(this.editingModal.data);
        }
      } catch (error) {
        this.setErrorNotification(error.message);
      }
      await this.loadDataAction();
      this.editingModal.show = false;
      this.setGeneralProgress(false);
      this.loader = false;
    },

    getPaymentMethodById(id) {
      return this.paymentMethods.find((e) => e.id === id) ?? {};
    },
  },
};
