//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState, mapGetters, mapActions } from 'vuex';
// import Filters from './TableFilters.vue';
import constant from '~/const';
import Modal from './Modal.vue';

import tableConfig from './tableConfig';
import tableExtraConfig from './tableExtraConfig';
import actionsConfig from './actionsConfig';

export default {
  components: { Modal },

  data() {
    return {
      tableConfig,
      tableExtraConfig,
      actionsConfig,
      selection: [],
      loader: false,
      constant,
      editingModal: {
        show: false,
        data: {},
        isCreating: false,
      },
    };
  },

  // SINGLE TABLE COMPONENT
  // async mounted() {
  //   const { page } = this.$route.query;
  //   const numberPage = Number(page);
  //   if (numberPage) {
  //     this.setPageAction(numberPage);
  //   }

  //   this.setGeneralLoader(true);
  //   await this.loadData();
  //   this.setGeneralLoader(false);
  // },

  computed: {
    ...mapState('common', ['generalLoader']),
    ...mapGetters('common', ['itemsPerPage']),

    ...mapState('tradersTransactionMethods', ['page', 'pages', 'data', 'sort']),
  },

  methods: {
    ...mapActions('common', [
      'setGeneralLoader',
      'setGeneralProgress',
      'setSuccessNotification',
      'setErrorNotification',
    ]),
    ...mapActions('tradersTransactionMethods', {
      loadDataAction: 'loadData',
      setPageAction: 'setPage',
      setSortAction: 'setSort',
      editAction: 'edit',
      createAction: 'create',
    }),

    async loadData(data = {}) {
      this.setGeneralProgress(true);
      this.loader = true;
      await this.loadDataAction(data);
      this.setGeneralProgress(false);
      this.loader = false;
    },

    applyFilters() {
      const { page } = this;
      if (page > 1) {
        this.setPageAction(1);
        this.$router.replace({ query: {} });
      }

      this.loadData();
    },

    applySort(data) {
      this.setSortAction(data);
      this.loadData();
    },

    setPage(value) {
      this.setPageAction(value);

      // SINGLE TABLE COMPONENT
      // const query = { page: value };
      // this.$router.replace({ query });

      const extendedRequestData = {
        getTotal: false,
      };

      this.loadData(extendedRequestData);
    },

    actionHandler({ action, payload }) {
      this[action](payload);
    },

    openModalEdit(row) {
      // eslint-disable-next-line no-undef
      this.editingModal.data = structuredClone ? structuredClone(row) : { ...row };
      this.editingModal.isCreating = false;
      this.editingModal.show = true;
    },

    openModalCreate() {
      this.editingModal.data = null;
      this.editingModal.isCreating = true;
      this.editingModal.show = true;
    },

    async apply({ input }) {
      this.setGeneralProgress(true);
      this.loader = true;
      if (this.editingModal.isCreating) {
        await this.createAction(input);
      } else {
        await this.editAction(input);
      }
      await this.loadDataAction();
      this.editingModal.show = false;
      this.setGeneralProgress(false);
      this.loader = false;
    },
  },
};
