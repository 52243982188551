export default [
  {
    width: 13,
    header: {
      type: 'text',
      caption: 'Id',
    },
    cell: {
      type: 'text',
      value: (e) => e.id,
    },
  },
  {
    width: 13,
    header: {
      type: 'text',
      caption: 'Name',
    },
    cell: {
      type: 'text',
      value: (e) => e.name,
    },
  },
  {
    width: 13,
    header: {
      type: 'text',
      caption: 'Title',
    },
    cell: {
      type: 'slot',
      name: 'title',
    },
  },
  {
    width: 13,
    header: {
      type: 'text',
      caption: 'Status',
    },
    cell: {
      type: 'slot',
      name: 'status',
    },
  },
  {
    width: 13,
    header: {
      type: 'text',
      caption: 'Template',
    },
    cell: {
      type: 'slot',
      name: 'template',
    },
  },
  {
    width: 13,
    header: {
      type: 'text',
      caption: '',
    },
    cell: {
      type: 'slot',
      name: 'controls',
    },
  },
];
